import styled from 'styled-components'
import media from 'styled-media-query'
import { alvTokens } from '@dasa-health/alma-tokens'
import { Container } from 'components/shared/container'
import { Link } from 'gatsby'

export const ExamLocationsWrapper = styled(Container)`
  max-width: calc(100vw - 6rem);
  padding-bottom: 24px;

  ${media.lessThan('medium')`
    padding: 0 1.6rem;
    width: calc(100vw - 3.2rem);
  `}
`

export const FilterRowWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 5rem;
`

export const FilterContainer = styled.div`
  margin-bottom: 2rem;
  width: 30rem;

  ${media.lessThan('medium')`
    width: 100%;
  `}
`

export const FilterTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  color: var(--pink);
  text-transform: uppercase;
  margin-bottom: 2rem;
`

export const CardsContainer = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  list-style-type: none;

  ${media.lessThan('small')`
    padding-left: 0;
  `}

  ${media.between('medium', 'huge')`
    padding-left: 0;
  `}
`

export const ErrorMessage = styled.p`
  color: var(--pink);
  text-align: center;
  font-size: 40px;
`

export const SearchMessage = styled.p`
  font-size: 14px;
  text-decoration: none;
  font-weight: 600;
  color: var(--white);
  background: ${props => props.theme.colors.brand.primary[10]};
  text-transform: uppercase;
  width: 30%;
  text-align: center;
  margin: 0 auto;

  ${media.lessThan('medium')`
    width:70%;
  `}

  padding: 1rem;
  border-radius: 4px;
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }
`

export const ExamLocationsTitle = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  color: ${props => props.theme.colors.brand.primary[10]};
  margin: 2.4rem 0;

  ${media.lessThan('medium')`
    font-size: 20px;
    margin: 0 0 2.4rem;
  `}
`

export const ExamLocationsDescription = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: ${alvTokens.ColorPaletteGray30};
  max-width: 75.9rem;
  margin: 0 0 4rem;

  ${media.lessThan('medium')`
    max-width: 100%;
    margin: 0 0 6.4rem;
    
  `}
`

export const UnitsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;

  ${media.lessThan('medium')`
    padding-bottom: 2rem;
  `}
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  ${media.lessThan('medium')`
    min-width: 250px ;
  `}
`

export const CardsContainerUnits = styled.div`
  box-sizing: border-box;
  font-size: 0.875rem;
  color: #fefefe;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-width: 0;
  word-wrap: break-word;
  padding: 24px;
  width: 310px;
  min-height: 328px;
  gap: 16px;
  height: 100%;
`

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 40px;
`

export const ComboBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  align-items: center;

  ${media.lessThan('medium')`
    flex-direction: column;
    align-items: center;
  `}
`

export const SelectItens = styled.select`
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #f7f7f7;
  border-radius: 4px 4px 0px 0px;
  display: block;
  width: 100%;
  font-size: 1.2rem;
  font-weight: 700 !important;
  letter-spacing: 0.5px !important;
  color: #333 !important;
  min-width: 200px;
  cursor: pointer;
`
export const OptionItens = styled.option`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 1rem 0;
  line-height: 1rem;
  font-size: 1.2rem;
  cursor: pointer;

  &::placeholder {
    color: #333;
  }
`
export const TitlePage = styled.h1`
  font-size: 4rem;
  margin: 0 0 2rem;
  color: var(--pink);

  ${media.lessThan('medium')`
    text-align: center;
    font-size: 2.5rem;
  `}
`
export const TitleCard = styled.h2`
  font-size: 2.2em;
  padding: 0 0 1rem;
  color: var(--pink);
  border-bottom: 1px solid #1616163D;
`

export const SubtitleCard = styled.p`
  color: #363636;
  font-size: 1.4em;
  line-height: 1em;
`
export const SubtitleBrandCard = styled.p`
  color: #000E40;
  font-size: 1.8em;
  font-weight: bold;
  line-height: 1.4em;
`
export const LinkCard = styled(Link)`
  color: #333;
  font-size: 1.2em;
  line-height: 2em;
  color: var(--pink);
  cursor: pointer;
  text-decoration: none;
`
export const LinkCardExternal = styled.a`
  font-size: 1.1em;
  line-height: 1em;
  color: #363636;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
`

export const DetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid #1616163D;
  margin-top: 1rem;
`
export const InputCustom = styled.input`
  padding: 1rem;
  background: #fff;
  border: 1px solid #f7f7f7;
  border-radius: 4px 4px 0px 0px;
  display: block;
  font-size: 1.2rem;
  font-weight: 700;
  color: #333;
  max-width: 200px;

  ${media.lessThan('medium')`
    max-width: 250px;
  `}
`

export const ContentRangeInput = styled.div`
  margin: 0.3rem 0 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4.5rem;
`
export const TitleRangeInput = styled.p`
  font-size: 18px;
  color: var(--brand-dasa);
  font-weight: 600;

  ${media.lessThan('medium')`
    text-align:center;
  `}
`

export const TitleSelect = styled.span`
  font-size: 12px;
  font-weight: 600;
`

export const ContentModal = styled.div`
  position: fixed;
  top: 100px;
  left: calc(50% - 250px);
  min-width: 250px;
  border: 1px solid #333;
  border-radius: 5px;
  padding: 1.2rem;
  background-color: #fff;
  z-index: 99;
`
export const ContainerIconClose = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  svg {
    cursor: pointer;
  }
`
export const ContainerText = styled.div`
  padding: 0 0.7rem 0.7rem;

  svg {
    color: #333;
    border: 1px solid #333;
    border-radius: 50%;
    padding: 4px 5px;
    background-color: transparent;
  }
`
export const TextInfoModal = styled.p`
  font-size: 14px;
  color: var(--brand-dasa);
  font-weight: 600;
  text-align: center;
  padding: 0.3rem 0.7rem 0 0;

  span {
    font-size: 12px;
  }

  ${media.lessThan('medium')`
    text-align:center;
    font-size: 12px;
  `}
`

export const ButtonScheduleExam = styled.button`
    border: 1px solid #000E40;
    border-radius: 4px;
    display: flex;
    gap: 5px;
    padding: 8px;
    color: #000E40;
    background-color: transparent;
    width: 141px;
    font-weight: bold;
    font-size: 1.2rem;
    justify-self: end;
`

export const ImageMarcas = styled.img`
    align-self: start;
`